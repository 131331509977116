<template>
  <div class="main">
    <div class="sub-course content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <span>Dashboard</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> Course </a-breadcrumb-item>
        <a-breadcrumb-item> Sub Course </a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="title" v-if="course">{{course.title}}</div>
          <div class="add">
            <a-button type="primary">
              <router-link :to="{ name: 'Course Add', params: { id: courseId }}"><PlusOutlined /> Add Sub Course</router-link>
            </a-button>
            <a-divider type="vertical" />
            <a-button type="primary">
              <router-link to="/course"><ArrowLeftOutlined />  Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <ListCourse :courseType="courseType" :courseId="courseId" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'

import ListCourse from '@/components/agency/course/ListCourse.vue'

import courseService from '../../../services/course'

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    ListCourse,
    ArrowLeftOutlined
  },
  setup () {
    const route = useRoute()
    const showWidget = ref<boolean>(true)
    const courseType = ref<string>('Child')
    const courseId = ref<string>(route.params.id as string)
    const course = ref<{ _id: string; title: string; description: string; image: string; category: object; discount: number; price: number; published: boolean; type: string }>()
    const getParentCourseDetails = async () => {
      const responce = await courseService.getCourseDetails(courseId.value)
      course.value = responce.data
    }
    onMounted(() => {
      console.log('yes')
      getParentCourseDetails()
    })
    return {
      showWidget,
      courseType,
      courseId,
      course
    }
  }
})
</script>

<style lang="scss">
.sub-course {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .title {
      float: left;
      font-family: "TT Norms Pro Medium";
    }
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
